/* eslint-disable */

<template>
  <div v-if="data_json">
    <vue-sortable :options="options" @drag:start="startedDrag" @drag:stop="stoppedDrag" @sortable:sort="sort">
      <div class="vx-row" :class="{ 'cursor-drag': draggingCard === true }">
        <div class="vx-col lg:w-3/12 md:w-3/12 sm:w-5/5">
          <vx-card class="mb-base">
            <h3 class="font-bold mb-4">Controls</h3>
            <vue-draggable-container id="wordBank" class="wordBank">
              <h5 class="dragBlock font-semibold cursor-pointer" v-for="i in controls" :key="i">
                {{ i }}
              </h5>
            </vue-draggable-container>
          </vx-card>
        </div>
        <div class="vx-col lg:w-9/12 md:w-9/12 sm:w-5/5">
          <vx-card class="mb-base" v-if="data_json && data_json.type === 0">
            <div class="grid grid-flow-col auto-cols-fr gap-4">
              <div v-for="item in inputs" :key="item.id">
                <div class="flex justify-center align-middle">
                  <div class="flex items-center justify-center align-middle" style="min-height: 75px">
                    <h1 style="font-size: 30px" class="font-bold mb-1 text-center">{{ item.name }}</h1>
                  </div>
                </div>
                <vue-draggable-container
                  class="answerStandard dropzone dropzoneStandard answer-bg my-4 lgcySmAnswer"
                  :class="'drop_input_' + item.id"
                  v-for="answer in item.answers"
                  :key="answer"
                  :id="Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)"
                />
              </div>
            </div>
          </vx-card>
          <vx-card class="mb-base" v-if="data_json && data_json.type === 1">
            <div class="grid grid-flow-col auto-cols-fr gap-4">
              <div v-for="item in inputs" :key="item.id">
                <div class="flex items-center justify-center align-middle">
                  <div class="flex items-center justify-center align-middle" style="min-height: 75px">
                    <h1 style="font-size: 30px" class="font-bold mb-1 text-center">{{ item.name }}</h1>
                  </div>
                </div>
                <div
                  class="answerStandard dropzone dropzoneStandard my-4 cursor-not-allowed items-start justify-start"
                  v-for="answer in item.answers"
                  :key="answer"
                  v-if="item.id === 1"
                >
                  <h5
                    class="dragBlock my-0 font-semibold cursor-not-allowed bg-none w-full h-full"
                    style="background-color: #040815; border: 1px solid #445877 !important"
                  >
                    {{ answer }}
                  </h5>
                </div>
                <vue-draggable-container
                  v-if="item.id === 0"
                  class="answerStandard dropzone dropzoneStandard answer-bg my-4 lgcySmAnswer"
                  :class="'drop_input_' + item.id"
                  v-for="(answer, index) in item.answers"
                  :key="answer"
                  :id="'drop_input_id_' + index"
                />
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vue-sortable>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import Prism from 'vue-prism-component';
import shuffle from 'shuffle-array';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      data_json: null,
      draggingCard: false,

      controls: [],
      inputs: {
        left: 0,
        right: 0,
      },

      options: {
        draggable: '.dragBlock',
        dropzone: '.dropzoneStandard',
      },
    };
  },
  methods: {
    startedDrag(x) {
      x.data.source.className += ' currently-dragging-standard';
      this.draggingCard = true;
    },
    stoppedDrag() {
      this.draggingCard = false;
    },
    sort(x) {
      const overContainer = x.data.dragEvent.data.overContainer;
      const sourceContainer = x.data.dragEvent.data.sourceContainer;
      if (overContainer && overContainer.id !== 'wordBank' && overContainer.children) {
        if (overContainer.children.length > 0 && overContainer.id !== sourceContainer.id) {
          x.cancel();
        }
      }
    },
    markSimulation() {
      let totalScore = 0;

      const all_inputs = document.getElementsByClassName('lgcySmAnswer');
      for (const input of all_inputs) {
        document.getElementById(input.id).style.borderColor = '#fd4445';
        document.getElementById(input.id).style.backgroundColor = '#fd4445';
      }

      if (this.data_json.type === 0) {
        this.inputs.forEach((x) => {
          const inputs = document.getElementsByClassName(`drop_input_${x.id}`);
          for (const item of inputs) {
            if (x.answers.includes(item.textContent.trim())) {
              totalScore++;
              document.getElementById(item.id).style.borderColor = '#28C76F';
              document.getElementById(item.id).style.backgroundColor = '#28C76F';
            }
          }
        });
      }

      if (this.data_json.type === 1) {
        this.inputs.forEach((x) => {
          if (x.id === 0) {
            x.answers.forEach((y, i) => {
              const input = document.getElementById(`drop_input_id_${i}`);
              if (input.textContent.trim() === y) {
                totalScore++;
                input.style.borderColor = '#28C76F';
                input.style.backgroundColor = '#28C76F';
              }
            });
          }
        });
      }

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    draggable,
    Prism,
    shuffle,
  },
  async created() {
    await this.dynamicJSON();

    const controls_temp = [];

    if (this.data_json.type === 0) {
      this.data_json.simulation.forEach((x) => {
        x.answers.forEach((i) => {
          controls_temp.push(i);
        });
      });
    }

    if (this.data_json.type === 1) {
      this.data_json.simulation.forEach((x) => {
        if (x.id === 0) {
          x.answers.forEach((i) => {
            controls_temp.push(i);
          });
        }
      });
    }

    if (this.data_json.controls) {
      this.data_json.controls.forEach((x) => {
        controls_temp.push(x);
      });
    }

    this.inputs = this.data_json.simulation;
    this.controls = shuffle(controls_temp);
  },
  computed: {
    dynamicJSON() {
      // eslint-disable-next-line vue/no-async-in-computed-properties
      return async () => {
        try {
          // Try to import the primary file based on `simulation.id`
          // eslint-disable-next-line vue/no-async-in-computed-properties
          const myData = await import(`./Lgx${this.dataLocal.simulation.id}`).then(({ default: myData }) => myData);
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.data_json = myData;
        } catch (error) {
          console.error(`Failed to load module for simulation.id, trying legacyId. Error: ${error}`);
          // eslint-disable-next-line vue/no-async-in-computed-properties
          const myData = await import(`./Lgx${this.dataLocal.simulation.legacyId}`).then(({ default: myData }) => myData);
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.data_json = myData;
        }
      };
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
#wordBank {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.6rem;
}
</style>
